import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from "styled-components";
import axios from 'axios'
import { mobile } from "../../MainPages/Home/responsive";


const Container = styled.div`
  width: 97vw;
  height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("https://images.pexels.com/photos/6984650/pexels-photo-6984650.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940")
      center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 27%;
  padding: 20px;
  background-color: white;
  ${mobile({ width: "80%" })}
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 10px 0;
  padding: 10px;
  color: black;
`;

const Button = styled.button`
  width: 100%;
  border: none;
  padding: 15px 20px;
  background-color: teal;
  color: white;
  cursor: pointer;
  margin-bottom: 10px;
`;



const Login = () => {
    const [user, setUser] = useState({
        email: '',
        password: ''
    });

    const onChangeInput = e => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value })
    }

    const loginGuest = () => {

    }

    const loginSubmit = async e => {
        e.preventDefault()
        try {
            await axios.post('/user/login', { ...user });

            localStorage.setItem('firstLogin', true);
            
            window.location.href = "/";
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    return (
        <>
            <Container>
                <Wrapper>
                    <Title>SIGN IN</Title>
                    <Form onSubmit={loginSubmit}>
                        <Input type="email"
                            name="email"
                            required
                            placeholder="Email"
                            value={user.email}
                            onChange={onChangeInput}
                        />
                        <Input type="password"
                            name="password"
                            required
                            placeholder="Парола"
                            value={user.password}
                            autoComplete="on"
                            onChange={onChangeInput} />
                        <Button type="submit">Влез</Button>
                       
                    </Form>
                    <Button onClick={() => loginGuest()}>Влез Като Гост</Button>
                    <Link style={{color: "black"}} to="/register">Регистрация</Link>
                </Wrapper>
            </Container>
        </>
    )
}

export default Login
