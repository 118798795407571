import { BrowserRouter as Router } from 'react-router-dom';
import MessengerCustomerChat from 'react-messenger-customer-chat';

import { DataProvider } from './GlobalState';
import Header from './components/Headers/Header';
import Pages from './components/MainPages/Pages';
import Footer from './components/Footer/Footer'

function App() {
  return (
    <DataProvider>
      <Router>
        <div className="App">
          <Header />
          <Pages />
          <Footer />
        </div>
        <MessengerCustomerChat
            pageId="111707133943126"
            appId="1010702149773780"
          />,
      </Router>
    </DataProvider>
  );
}

export default App;
