import React, { useContext, useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { GlobalState } from '../../../GlobalState';
import ProductItem from '../utils/ProductItem/ProductItem';
import Colors from '../utils/Colors/Colors';
import Sizes from '../utils/Size/Size';


const DetailProduct = () => {
    const params = useParams();
    const state = useContext(GlobalState);
    const [products] = state.productsAPI.products;
    const addCart = state.userAPI.addCart
    const [detailProduct, setDetailProduct] = useState([]);

    useEffect(() => {
        if (params.id) {
            products.forEach(product => {
                if (product._id === params.id) {
                    setDetailProduct(product)
                }
            })
        }
    }, [params._id, products])

    if (detailProduct.length === 0) {
        return null;
    }
    return (
        <>
            <div className="detail">
                <div className="img-detail">
                    <img src={detailProduct.images.url} alt="" />
                    <img src={detailProduct.imagesTwo.url} alt="" />
                    <img src={detailProduct.imagesThree.url} alt="" />
                    <img src={detailProduct.imagesFour.url} alt="" />
                </div>
                <div className="box-detail">
                    <div className="row">
                        <h2>{detailProduct.title}</h2>
                        <h6>#id: {detailProduct.product_id}</h6>
                    </div>
                    <hr />
                    <span style={detailProduct.sale ? { color: "#CC2128" } : {}}>{Number(detailProduct.price).toFixed(2)} лв.</span>
                    <br />
                    <span style={detailProduct.sale ? { textDecoration: "line-through" } : {}}>{detailProduct.sale ? detailProduct.sale + "лв." : ""} </span>
                    <p>{detailProduct.description}</p>
                    <p>{detailProduct.content}</p>
                    <p>Цвят: {<Colors colors={detailProduct.colors} />}</p>
                    <p>Размер: {<Sizes sizes={detailProduct.size} />}</p>
                    <p>Продадени: {detailProduct.sold}</p>
                    <Link style={detailProduct.sold_out === 'sold_out' ? { pointerEvents: "none" } : {}} onClick={() => addCart(detailProduct)} to="/cart" className="cart">
                        {
                            detailProduct.sold_out === 'sold_out' ? "Изчерпан" : " Купи"
                        }                    
                    </Link>
                </div>
            </div>
            <hr />
            <div>
                <h2>Свързани продукти</h2>
                <div className="products">
                    {
                        products.map(product => {
                            return product.category === detailProduct.category
                                ? <ProductItem key={product._id} product={product} /> : null
                        })
                    }
                </div>
            </div>

        </>
    )
}

export default DetailProduct