import React,{useContext} from 'react'
import {Switch,Route} from 'react-router-dom';

import Products from './Products/Products';
import DetailProduct from './DetailProduct/DetailProduct';
import Login from './Auth/Login';
import Register from './Auth/Register';
import Cart from './Cart/Cart';
import NotFound from './utils/NotFound';
import Home from './Home/Home';
import Categories from './Categories/Categories';
import CreateProduct from './CreateProduct/CreateProduct';
import Contact from './contact/Contact';
import CreateBanner from './CreateBanner/CreateBanner';
import Banners from './Banners/Banners';


import {GlobalState} from '../../GlobalState';

const Pages = () => {
    const state = useContext(GlobalState);
    const [isLogged] = state.userAPI.isLogged;
    const [isAdmin] = state.userAPI.isAdmin;

    return (
       <Switch>
           <Route path="/" exact component={Home} />

           <Route path="/products" exact component={Products} />
           <Route path="/detail/:id" exact component={DetailProduct} />

           <Route path="/login" exact component={isLogged ? NotFound :  Login} />
           <Route path="/register" exact component={isLogged ? NotFound :  Register} />

           <Route path="/contact" exact component={Contact} />
           
           <Route path="/category" exact component={isAdmin ? Categories :  NotFound} />
           <Route path="/create_product" exact component={isAdmin ? CreateProduct :  NotFound} />
           <Route path="/create_banner" exact component={isAdmin ? CreateBanner :  NotFound} />
           <Route path="/edit_banner/:id" exact component={isAdmin ? CreateBanner :  NotFound} />
           <Route path="/banners" exact component={isAdmin ? Banners :  NotFound} />
           <Route path="/edit_product/:id" exact component={isAdmin ? CreateProduct :  NotFound} />


           <Route path="/cart" exact component={Cart} />

           <Route path="*" exact component={NotFound} />
       </Switch>
    )
}

export default Pages
