import React from 'react'
import BtnRender from './BtnRender'

const BannerItem = ({banner, isAdmin, deleteBanner}) => {
    return (
        <div className="banner_card">
        <img  src={banner.images.url} alt="" />

        <div className="banner_box">
            <h2>{banner.title}</h2>
        </div>

       
        <BtnRender banner={banner} deleteBanner={deleteBanner} />
    </div>
    )
}

export default BannerItem
