import React,{ useState, useContext} from 'react'
import { GlobalState } from '../../../../GlobalState';
import axios from 'axios';


const Colors = ({colors, id}) => {
    const state = useContext(GlobalState);
    const [cart, setCart] = state.userAPI.cart;
    const [token] = state.token;

    const addToCart = async () => {
        await axios.patch('/user/addcart', { cart }, {
            headers: { Authorization: token }
        })
    }

    const changeColor = (id, colorItem) => {
        cart.forEach(item => {
            if (item._id === id) {
                item.color = colorItem;
            }
        });

        setCart([...cart]);
        addToCart();
    }


    return (
        
        <div className="colors"  style={{display: 'inline-block', marginLeft: '10px' }}>
            {
                colors.map((colorItem, index) => (
                    <button value={index} onClick={() => changeColor(id, colorItem)} style={{background: colorItem}} key={index}></button>
                ))
            }
        </div>
    )
}

export default Colors