import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios';
import { GlobalState } from '../../../GlobalState';
import Loading from '../utils/loading/Loading';
import { useHistory, useParams } from 'react-router-dom';

const initialState = {
    product_id: '',
    title: '',
    colors: '',
    size: '',
    gender: '',
    sold_out: '',
    price: 0,
    sale: 0,
    description: 'How to and tutorial videos of cool CSS effect, Web Design ideas,JavaScript libraries, Node.',
    content: 'Welcome to our channel Dev AT. Here you can learn web designing, UI/UX designing, html css tutorials, css animations and css effects, javascript and jquery tutorials and related so on.',
    category: '',
    _id: ''
}

const CreateProduct = () => {
    const state = useContext(GlobalState);
    const [product, setProduct] = useState(initialState);
    const [categories] = state.categoriesAPI.categories;
    const [images, setImages] = useState(false);
    const [imagesTwo, setImagesTwo] = useState(false);
    const [imagesThree, setImagesThree] = useState(false);
    const [imagesFour, setImagesFour] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingTwo, setLoadingTwo] = useState(false);
    const [loadingThree, setLoadingThree] = useState(false);
    const [loadingFour, setLoadingFour] = useState(false);

    const [isAdmin] = state.userAPI.isAdmin
    const [token] = state.token

    const history = useHistory();
    const param = useParams();

    const [products] = state.productsAPI.products;
    const [onEdit, setOnEdit] = useState(false);
    const [callback, setCallback] = state.productsAPI.callback;

    //edit product
    useEffect(() => {
        if (param.id) {
            setOnEdit(true);
            products.forEach(product => {
                if (product._id === param.id) {
                    setProduct(product);
                    setImages(product.images);
                    setImagesTwo(product.imagesTwo);
                    setImagesThree(product.imagesThree);
                    setImagesFour(product.imagesFour);
                }
            })

        } else {
            setOnEdit(false);
            setProduct(initialState);
            setImages(false);
            setImagesTwo(false);
            setImagesThree(false);
            setImagesFour(false);
        }
    }, [param.id])

    const handleUpload = async e => {
        e.preventDefault()
        try {
            if (!isAdmin) return alert("You're not an admin")
            const file = e.target.files[0]

            if (!file) return alert("File not exist.")

            if (file.size > 1024 * 1024) // 1mb
                return alert("Size too large!")

            if (file.type !== 'image/jpeg' && file.type !== 'image/png') // 1mb
                return alert("File format is incorrect.")

            let formData = new FormData()
            formData.append('file', file)

            setLoading(true)
            const res = await axios.post('/api/upload', formData, {
                headers: { 'content-type': 'multipart/form-data', Authorization: token }
            })

            setLoading(false)
            setImages(res.data)

        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleUploadTwo = async e => {
        e.preventDefault()
        try {
            if (!isAdmin) return alert("You're not an admin")
            const file = e.target.files[0]

            if (!file) return alert("File not exist.")

            if (file.size > 1024 * 1024) // 1mb
                return alert("Size too large!")

            if (file.type !== 'image/jpeg' && file.type !== 'image/png') // 1mb
                return alert("File format is incorrect.")

            let formData = new FormData()
            formData.append('file', file)

            setLoadingTwo(true)
            const res = await axios.post('/api/upload', formData, {
                headers: { 'content-type': 'multipart/form-data', Authorization: token }
            })

            setLoadingTwo(false)
            setImagesTwo(res.data)

        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleUploadThree = async e => {
        e.preventDefault()
        try {
            if (!isAdmin) return alert("You're not an admin")
            const file = e.target.files[0]

            if (!file) return alert("File not exist.")

            if (file.size > 1024 * 1024) // 1mb
                return alert("Size too large!")

            if (file.type !== 'image/jpeg' && file.type !== 'image/png') // 1mb
                return alert("File format is incorrect.")

            let formData = new FormData()
            formData.append('file', file)

            setLoadingThree(true)
            const res = await axios.post('/api/upload', formData, {
                headers: { 'content-type': 'multipart/form-data', Authorization: token }
            })

            setLoadingThree(false)
            setImagesThree(res.data)

        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleUploadFour = async e => {
        e.preventDefault()
        try {
            if (!isAdmin) return alert("You're not an admin")
            const file = e.target.files[0]

            if (!file) return alert("File not exist.")

            if (file.size > 1024 * 1024) // 1mb
                return alert("Size too large!")

            if (file.type !== 'image/jpeg' && file.type !== 'image/png') // 1mb
                return alert("File format is incorrect.")

            let formData = new FormData()
            formData.append('file', file)

            setLoadingFour(true)
            const res = await axios.post('/api/upload', formData, {
                headers: { 'content-type': 'multipart/form-data', Authorization: token }
            })

            setLoadingFour(false)
            setImagesFour(res.data)

        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleDestroy = async () => {
        try {
            if (!isAdmin) return alert("Вие не сте администратор!")
            setLoading(true)
            await axios.post('/api/destroy', { public_id: images.public_id }, {
                headers: { Authorization: token }
            })
            setLoading(false)
            setImages(false)
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleDestroyTwo = async () => {
        try {
            if (!isAdmin) return alert("Вие не сте администратор!")
            setLoadingTwo(true)
            await axios.post('/api/destroy', { public_id: imagesTwo.public_id }, {
                headers: { Authorization: token }
            })
            setLoadingTwo(false)
            setImagesTwo(false)
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleDestroyThree = async () => {
        try {
            if (!isAdmin) return alert("Вие не сте администратор!")
            setLoadingThree(true)
            await axios.post('/api/destroy', { public_id: imagesThree.public_id }, {
                headers: { Authorization: token }
            })
            setLoadingThree(false)
            setImagesThree(false)
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleDestroyFour = async () => {
        try {
            if (!isAdmin) return alert("Вие не сте администратор!")
            setLoadingFour(true)
            await axios.post('/api/destroy', { public_id: imagesFour.public_id }, {
                headers: { Authorization: token }
            })
            setLoadingFour(false)
            setImagesFour(false)
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleChangeInput = e => {
        const { name, value } = e.target
        setProduct({ ...product, [name]: value })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        try {
            if (!isAdmin) return alert("You're not an admin")
            if (!images) return alert("No Image Upload")
            if (!imagesTwo) return alert("No Image Upload")
            if (!imagesThree) return alert("No Image Upload")
            if (!imagesFour) return alert("No Image Upload")

            if (onEdit) {
                await axios.put(`/api/products/${product._id}`, { ...product, images, imagesTwo, imagesThree, imagesFour }, {
                    headers: { Authorization: token }
                });
            } else {
                await axios.post(`/api/products/`, { ...product, images, imagesTwo, imagesThree, imagesFour }, {
                    headers: { Authorization: token }
                });
            }
            setCallback(!callback);

            setProduct(initialState);

            history.push("/products");


        } catch (err) {
            alert(err.response.data.msg)
        }
    }


    const styleUpload = {
        display: images ? "block" : "none"
    }

    const styleUploadTwo = {
        display: imagesTwo ? "block" : "none"
    }

    const styleUploadThree = {
        display: imagesThree ? "block" : "none"
    }

    const styleUploadFour = {
        display: imagesFour ? "block" : "none"
    }


    return (
        <div className="create_product">
            <div className="upload">
                <input type="file" name="file" id="file_up" onChange={handleUpload} />
                {
                    loading ? <div id="file_img"><Loading /></div>

                        : <div id="file_img" style={styleUpload}>
                            <img src={images ? images.url : ''} alt="" />
                            <span onClick={handleDestroy}>X</span>
                        </div>
                }

            </div>

            <div className="upload">
                <input type="file" name="file" id="file_up_two" onChange={handleUploadTwo} />
                {
                    loadingTwo ? <div id="file_img"><Loading /></div>

                        : <div id="file_img" style={styleUploadTwo}>
                            <img src={imagesTwo ? imagesTwo.url : ''} alt="" />
                            <span onClick={handleDestroyTwo}>X</span>
                        </div>
                }
            </div>

            <div className="upload">
                <input type="file" name="file" id="file_up_three" onChange={handleUploadThree} />
                {
                    loadingThree ? <div id="file_img"><Loading /></div>

                        : <div id="file_img" style={styleUploadThree}>
                            <img src={imagesThree ? imagesThree.url : ''} alt="" />
                            <span onClick={handleDestroyThree}>X</span>
                        </div>
                }
            </div>

            <div className="upload">
                <input type="file" name="file" id="file_up_four" onChange={handleUploadFour} />
                {
                    loadingFour ? <div id="file_img"><Loading /></div>

                        : <div id="file_img" style={styleUploadFour}>
                            <img src={imagesFour ? imagesFour.url : ''} alt="" />
                            <span onClick={handleDestroyFour}>X</span>
                        </div>
                }
            </div>

            <form onSubmit={handleSubmit}>
                <div className="row">
                    <label htmlFor="product_id">Product ID</label>
                    <input type="text" name="product_id" id="product_id"
                        value={product.product_id} onChange={handleChangeInput} disabled={onEdit} />
                </div>

                <div className="row">
                    <label htmlFor="title">Title</label>
                    <input type="text" name="title" id="title"
                        value={product.title} onChange={handleChangeInput} />
                </div>

                <div className="row">
                    <label htmlFor="price">Price</label>
                    <input type="number" name="price" id="price"
                        value={product.price} onChange={handleChangeInput} />
                </div>

                <div className="row">
                    <label htmlFor="price">Стара цена</label>
                    <input type="number" name="sale" id="sale"
                        value={product.sale} onChange={handleChangeInput} />
                </div>
                <div className="row">
                    <input className="radio_male" name="gender" type="radio" checked={product.gender == "male"}
                        onChange={handleChangeInput} value="male" /> Male

                    <input className="radio_male" name="gender" type="radio" checked={product.gender == "famale"}
                        onChange={handleChangeInput} value="famale" /> Famale

                </div>

                <div className="row">
                    <input className="radio_male" name="sold_out" type="radio" checked={product.sold_out == "sold_out"}
                        onChange={handleChangeInput} value="sold_out" /> Изчерпан

                    <input className="radio_male" name="sold_out" type="radio" checked={product.sold_out == "sold_out_not"}
                        onChange={handleChangeInput} value="sold_out_not" /> Не е изчерпан
                </div>

                <div className="row">

                    <label htmlFor="title">Colors</label>
                    <input type="text" name="colors" id="color"
                        value={product.colors} onChange={handleChangeInput} />
                </div>

                <div className="row">

                    <label htmlFor="title">Sizes</label>
                    <input type="text" name="size" id="size"
                        value={product.size} onChange={handleChangeInput} />
                </div>

                <div className="row">
                    <label htmlFor="description">Описание</label>
                    <textarea type="text" name="description" id="description" required
                        value={product.description} rows="5" onChange={handleChangeInput} />
                </div>

                <div className="row">
                    <label htmlFor="content">Съдържание</label>
                    <textarea type="text" name="content" id="content" required
                        value={product.content} rows="7" onChange={handleChangeInput} />
                </div>

                <div className="row">
                    <label htmlFor="categories">Categories: </label>
                    <select name="category" value={product.category} onChange={handleChangeInput} >
                        <option value="">Please select a category</option>
                        {
                            categories.map(category => (
                                <option value={category._id} key={category._id}>
                                    {category.name}
                                </option>
                            ))
                        }
                    </select>
                </div>

                <button type="submit">{onEdit ? "Update" : "Create"}</button>
            </form>
        </div>
    )
}

export default CreateProduct
