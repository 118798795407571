import { useState, useEffect } from 'react'
import axios from 'axios';


const BannersAPI = () => {

    const [banners, setBanners] = useState([]);
    const [callback, setCallback] = useState(false);
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [result, setResult] = useState(0)


    useEffect(() => {
        const getBanners = async () => {
            const res = await axios.get(`/api/banners?limit=${page*9}&title[regex]=${search}`);
            setBanners(res.data.banners);
            setResult(res.data.result);
        }
        getBanners();
    }, [callback, search, page])
    

    return {
        banners: [banners, setBanners],
        callback: [callback, setCallback],
        search: [search, setSearch],
        page: [page, setPage],
        result: [result, setResult],
    }
}

export default BannersAPI;
