import React,{ useState, useContext} from 'react'
import { GlobalState } from '../../../../GlobalState';
import axios from 'axios';

const Size = ({ sizes, id }) => {
    const state = useContext(GlobalState);
    const [cart, setCart] = state.userAPI.cart;
    const [token] = state.token;

    const addToCart = async () => {
        await axios.patch('/user/addcart', { cart }, {
            headers: { Authorization: token }
        })
    }

    const changeSize = (id, size) => {
        console.log(size);
        cart.forEach(item => {
            if (item._id === id) {
                item.size_choice = size;
            }
        });

        setCart([...cart]);
        addToCart();
    }
    
    return (
            <div className="sizes" >

                <select name="size" id="size" onChange={(event) => changeSize(id, event.target.value) } >
                    {
                        sizes.map((size, index) => (
                            <option value={size} key={index}>{size}</option>
                        ))
                    }
                </select>

            </div>
    )
}

export default Size
