import React, { useEffect, useContext } from 'react'
import Product from './Product';
import { GlobalState } from '../../../../GlobalState';
import axios from 'axios';
import styled from 'styled-components';
import './products.css';

const Container = styled.div`
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const Products = () => {
  const state = useContext(GlobalState);
  let [products, setProducts] = state.productsAPI.products;

  if(products.length > 8){
    products = products.slice(0, 8);
  }


  useEffect(() => {
    const getProducts = async () => {
      const res = await axios.get('/api/products');
      setProducts(res.data.products);
    }
    getProducts();
  }, [setProducts])

  return (
    <>
    <div className="div_new_offer">
      <h1>НАЙ - НОВИ ПРЕДЛОЖЕНИЯ</h1>
    </div>
    <Container>
      {products.map((item) => (
        <Product item={item} key={item._id} />
      ))}
    </Container>
    </>
  )
}

export default Products
