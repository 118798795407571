import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { GlobalState } from '../../../../GlobalState';

const BtnRender = ({ product, deleteProduct }) => {
    const state = useContext(GlobalState);
    const [isAdmin] = state.userAPI.isAdmin;
    const addCart = state.userAPI.addCart;

    return (
        <div className="row_btn">
            {
                isAdmin ?
                    <>
                        <Link id="btn_buy" to="#!" onClick={() => deleteProduct(product._id, product.images.public_id, product.imagesTwo.public_id, product.imagesThree.public_id, product.imagesFour.public_id)}>
                            Delete
                        </Link>
                        <Link id="btn_view" to={`/edit_product/${product._id}`}>
                            Edit
                        </Link>
                    </>
                    : <>
                        <Link id="btn_buy" style={product.sold_out === 'sold_out' ? {pointerEvents: "none"} : {}} to="#!" onClick={() => addCart(product)}>
                            {
                                product.sold_out === 'sold_out' ? "Изчерпан" : " Купи"
                            }
                           
                        </Link>
                        <Link id="btn_view" to={`/detail/${product._id}`}>
                            Разгледай
                        </Link>
                    </>
            }

        </div>
    )
}

export default BtnRender
