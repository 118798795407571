import React from 'react'
import BtnRender from './BtnRender'

const ProductItem = ({product, isAdmin, deleteProduct, handleCheck}) => {


    return (
        <div className="product_card">
            {
                isAdmin && <input type="checkbox" checked={product.checked} 
                onChange={() => handleCheck(product._id)}/>
            }
            <img style={product.sold_out === "sold_out" ? {filter: "grayscale(100%)"} : {}}  src={product.images.url} alt="" />
            <div style={product.sale ? {backgroundColor: ""} : { backgroundColor: "transparent", border: "transparent"} } className="sale_procent">{product.sale ? "-" + Number(((product.sale - product.price) / product.sale) * 100).toFixed(0) + "%" : ""}</div>

            <div className="product_box">
                <h2>{product.title}</h2>
                <span>{Number(product.price).toFixed(2)} лв.</span>
                <span className="sale" >{product.sale ? product.sale + " лв." : ""}</span>
                <p>{product.description}</p>
            </div>

           
            <BtnRender product={product} deleteProduct={deleteProduct} />
        </div>
    )
}

export default ProductItem
