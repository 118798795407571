import React, { useState, useContext, useEffect } from 'react'
import toastr from 'toastr';
import { GlobalState } from '../../../GlobalState';
import { Link } from 'react-router-dom';
import axios from 'axios';
import emailjs from 'emailjs-com';
import Colors from '../utils/Colors/Colors';
import Sizes from '../utils/Size/Size';


const Cart = () => {
    const state = useContext(GlobalState);
    const [user] = state.userAPI.user;
    const [cart, setCart] = state.userAPI.cart;
    const [token] = state.token;
    const [total, setTotal] = useState(0)
    
    console.log(cart);
    useEffect(() => {
        const getTotal = () => {
            const total = cart.reduce((prev, item) => {
                return prev + (item.price * item.quantity);
            }, 0);

            setTotal(total);
        }

        getTotal();
    }, [cart])

    const addToCart = async () => {
        await axios.patch('http://server.vrs-bg.com/user/addcart', { cart }, {
            headers: { Authorization: token }
        })
    }


    const increment = (id) => {
        cart.forEach(item => {
            if (item._id === id) {
                item.quantity += 1;
            }
        });

        setCart([...cart]);
        addToCart();
    }

    const decrement = (id) => {
        cart.forEach(item => {
            if (item._id === id) {
                item.quantity === 1 ? item.quantity = 1 : item.quantity -= 1;
            }
        });

        setCart([...cart]);
        addToCart();
    }

    const removeProdduct = (id) => {
        if (window.confirm("Сигурни ли сте, че искате да изтриете този продукт?")) {
            cart.forEach((item, index) => {
                if (item._id === id) {
                    cart.splice(index, 1);
                }
            });

            setCart([...cart]);
            addToCart();
        }
    }


    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm(
            'service_242r737',
            'template_63evh4b',
            e.target,
            'user_3ke5d9TV80hRpR6NmI9dY'
        ).then(res => {
            console.log(res);
        }).catch(err => console.log(err));

        toastr.success('Вашата поръчка е приета');
        setCart([]);

    }

    


    if (cart.length === 0) {
        return <h2 style={{ textAlign: "center", fontSize: "5rem" }}>Количката е празна</h2>
    }
    return (
        <div>
            {
                cart.map(product => (
                    <div className="detail cart" key={product._id}>
                        <img src={product.images.url} alt="" />
                        <div className="box-detail">
                            <h2>{product.title}</h2>

                            <h3>{Number(product.price * product.quantity).toFixed(2)} лв.</h3>
                            <p>{product.description}</p>
                            <p>Цвят: {<Colors id={product._id} colors={product.colors} />}</p>
                            {
                                product.color ?  <p>Избран Цвят: <div style={{backgroundColor: product.color}} className="color"></div></p> : " "
                            }
                           
                            <p>Размер: {<Sizes id={product._id} sizes={product.size} />}</p>
                            <div className="amount">
                                <button onClick={() => decrement(product._id)}> - </button>
                                <span>{product.quantity}</span>
                                <button onClick={() => increment(product._id)}> + </button>
                            </div>

                            <div className="delete" onClick={() => removeProdduct(product._id)}>X</div>
                        </div>
                    </div>
                ))
            }

            
            <div className="total">
                <h3>Сума: {Number(total).toFixed(2)} Лв.</h3>
                <Link to="#!">Payment</Link>
            </div>

            <form onSubmit={sendEmail}>
                <input type="hidden" name="name" value={user.name} />
                <input type="hidden" name="user_email" value={user.email}/>
                <input type="hidden" name="telephone_number" value={user.telephone_number}/>
                <input type="hidden" name="address" value={user.address}/>
                {
                    cart.map(product => (
                        <>
                        <input type="hidden" name="product_name" value={product.title}/>
                        <input type="hidden" name="product_quantity" value={product.quantity}/>
                        <input type="hidden" name="product_id" value={product.product_id}/>
                        <input type="hidden" name="product_size" value={product.size_choice}/>
                        <input type="hidden" name="product_color" value={product.color}/>
                        <input type="hidden" name="product_price" value={Number(product.price).toFixed(2) + "лв."}/>
                        <input type="hidden" name="product_price_all" value={Number(total).toFixed(2) + "лв."}/>

                        </>

                    ))
                }
                <input className="input_buy" type="submit" value="Поръчай" />
            </form>
        </div>
    )
}

export default Cart
