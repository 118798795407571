import React, {useState, useContext} from 'react';  
import { categories } from "../data";
import styled from "styled-components";
import { GlobalState } from '../../../../GlobalState';
import { mobile } from "../responsive";
import CategoryItem from "./CategoryItem";

const Container = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
  ${mobile({ padding: "0px", flexDirection:"column" })}
`;

const Categories = () => {
    const state = useContext(GlobalState)
    let [category] = state.categoriesAPI.categories;

    if(category.length){
      category = category.slice(0, 3);
    }

    return (
        <Container>
        {category.map((item) => (
          <CategoryItem  item={item} key={item.id} />
        ))}
      </Container>
    )
}

export default Categories
