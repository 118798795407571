import React, { useContext, useState } from 'react';
import { GlobalState } from '../../GlobalState';
import { Link } from 'react-router-dom';
import Menu from './icon/menu.svg';
import Close from './icon/close.svg';
import Cart from './icon/cart.svg';
import axios from 'axios';

const Header = () => {
    const state = useContext(GlobalState);

    const [isLogged] = state.userAPI.isLogged;
    const [isAdmin] = state.userAPI.isAdmin;
    const [cart] = state.userAPI.cart;
    const [menu, setMenu] = useState(false)

    const logoutUser = async () => {
        await axios.get('http://server.vrs-bg.com/user/logout');

        localStorage.removeItem('firstLogin');

        window.location.href = "/";
    }

    const adminRouter = () => {
        return (
            <>
                <li><Link to="/create_product">Създайте Продукт</Link></li>
                <li><Link to="/create_banner">Създайте Банер</Link></li>
                <li><Link to="/banners">Банери</Link></li>
                <li><Link to="/category">Kатегории</Link></li>
            </>
        )
    }

    const loggedRouter = () => {
        return (
            <>
                <li><Link to="/history">История</Link></li>
                <li><Link to="/" onClick={logoutUser}>Излез</Link></li>
            </>
        )
    }

    const styleMenu = {
        left: menu ? 0 : "-100%"
    }

    return (
        <header>
            <div className="menu" onClick={() => setMenu(!menu)}>
                <img src={Menu} alt="" width="30" />
            </div>

            <div className="logo">
                <h1>
                    <Link to="/">{isAdmin ? 'Admin' : <img src="https://res.cloudinary.com/nsys-ltd/image/upload/v1632489182/eccomerce/logo_rdtxrp.png" />}</Link>
                </h1>
            </div>

            <ul style={styleMenu}>
                {
                    isAdmin ? '' : <li><Link to="/">Начало</Link></li>
                }
                <li><Link to="/products">{isAdmin ? 'Products' : 'Продукти'}</Link></li>
    

                {isAdmin && adminRouter()}
                {
                    isLogged ? loggedRouter() : <li><Link to="/login">Login ✥ Register</Link></li>
                }

                <li><Link to="/contact">{isAdmin ? '' : 'Контакти'}</Link></li>


                <li onClick={() => setMenu(!menu)}>
                    <img src={Close} className="menu" alt="" width="30" />
                </li>
            </ul>


            {
                isAdmin ? '' 
                : <div className="cart-icon">
                    <span>{cart.length}</span>
                    <Link to="/cart">
                        <img src={Cart} alt="" width="30" />
                    </Link>
                </div>
            }



        </header>
    )
}

export default Header
