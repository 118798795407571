import React,{useState, useContext, useEffect} from 'react';
import axios from 'axios';

const UserAPI = (token) => {
    const [isLogged, setIsLogged] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [cart, setCart] = useState([]);
    const [user, setUser] = useState([]);
   
    
    useEffect(() => {
        if(token) {
            const getUser = async () => {
                try {
                    const res = await axios.get('/user/infor', {
                        headers: {Authorization: token}
                       
                    });

                    setUser(res.data)
                    setIsLogged(true);
                    res.data.role === 1 ? setIsAdmin(true) : setIsAdmin(false)

                    //като refresh да не ни изчезват продуктите от количката
                    setCart(res.data.cart);
                    
                } catch (err) {
                    alert(err.response.data.msg);
                }
            }

            getUser()
        }
    }, [token]);

    const addCart = async (product) => {
        if(!isLogged) {
             return alert("Моля влезте в профила си, за да добавите продукт в количката или влезте като Гост"); 
        }

        const check = cart.every(item => {
            return item._id !== product._id;
        });

        if(check) {
            setCart([...cart, {...product, quantity: 1}]);
            await axios.patch('http://server.vrs-bg.com/user/addcart', {cart: [...cart, {...product, quantity: 1}]}, {
                headers: {Authorization: token}
            })
        }else{
            alert("Този продукт вече е  добавен в количката")
        }
    }

    return {
        isLogged: [isLogged, setIsLogged],
        isAdmin:  [isAdmin, setIsAdmin],
        cart: [cart, setCart],
        addCart: addCart,
        user: [user]
    }
}

export default UserAPI
