import React, {  useContext, useState } from 'react'
import { GlobalState } from '../../../GlobalState';

import BannerItem from '../utils/BannerItem/BannerItem';
import Loading from '../utils/loading/Loading';
import axios from 'axios';


const Banner = () => {
    const state = useContext(GlobalState);
    const [banners, setBanners] = state.bannersAPI.banners;
    const [isAdmin] = state.userAPI.isAdmin;
    const [token] = state.token;
    const [callback, setCallback] = state.bannersAPI.callback;
    const [loading, setLoading] = useState(false);
   

    const deleteBanner = async(id, public_id) => {
   
        try {
          setLoading(true);
          const destroyImg = axios.post('/api/destroy', { public_id }, {
              headers: { Authorization: token }
          })
          const deleteBanner = axios.delete(`/api/banners/${id}`, {
              headers: { Authorization: token }
          })
         
          await destroyImg;
          await deleteBanner;
          setCallback(!callback);
          setLoading(false);
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    if(loading) return <div><Loading /></div>
    return (
        <div className="banners">
            {
                banners.map(banner => {
                    return <BannerItem key={banner._id} banner={banner} 
                    isAdmin={isAdmin} deleteBanner={deleteBanner} />
                })
            }
            
        </div>
    )
}

export default Banner
